$fg1: #000000;
$fg1-fade: #00000084;
$fg2: #e3cfa8;
$fg2-fade: #e3cfa884;
$bg1: #e3cfa8;
$bg2: #000000;
$bg3: #e3cfa8;
$highlight1: #e6212b;

//dark hard
// $fg1: #ffffff;
// $fg2: #000000;
// $fg2-fade: #ffffffb6;
// $bg1: #000000;
// $bg2: #ffffff;
// $bg3: #000000;
// $highlight1: #e6212b;

$d-shorter: 50ms;
$d-short: 100ms;
$d-standard: 200ms;
$d-long: 500ms;
$d-longer: 800ms;
$d-longest: 1500ms;

$b-lg: 1024px;
$b-md: 768px;
$b-sm: 576px;
$b-xs: 368px;
$gutter: 15px;
$col: calc(calc(#{$b-lg} - #{$gutter * 11}) / 12);

$z-sticky: 100;

$ease-in-out: cubic-bezier(.49,-0.01,.63,.99);
$ease-in-out-2: cubic-bezier(.46,0,.55,.99);

$shw1: 0px 4px 8px rgba(0, 0, 0, 0.25);