/* Firefox */
* {
    scrollbar-color: $bg1;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
    width: 16px;
    width: 16px;
}

*::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: $bg1;

    &:hover, &:active {
        background-color: $bg3;
    }
}

*::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: $fg1;

    &:hover {
        background-color: $highlight1;
    }

    &:active {
        background-color: $highlight1;
    }
}