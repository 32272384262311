@mixin cta-styles {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 2rem;
    height: fit-content;
    min-height: fit-content;
    border: 1px solid $fg1;
    border-radius: 100vh;
    text-align: center;
    font-size: 1.25rem;
    letter-spacing: 2px;
    font-weight: 700;
    text-decoration: none;
    transition: color $d-short $ease-in-out, background-color $d-short $ease-in-out, border-color $d-short $ease-in-out;
    cursor: pointer;
    user-select: none;
    // pointer-events: auto;
    word-break: normal;

    @media (max-width: $b-xs) {
        padding: 0.5rem 1.25rem;
        font-size: 1rem;
        line-height: 1.25rem;
    }
}

@mixin logo-type {
    cursor: pointer;
    user-select: none;
    pointer-events: auto;

    &--light {
        font-family: 'CeraBrush-Thin', sans-serif;
    }
}

@mixin logo-img {
    cursor: pointer;
    user-select: none;
    pointer-events: auto;
    width: auto;
    height: 5rem;
}

@mixin fade-in {
    animation: fade-in $d-longer $ease-in-out-2 $d-longer 1 both;

    @keyframes fade-in {
        0% {
            opacity: 0;
            transform: translateY(-50px);
        }

        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }
}