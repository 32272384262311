@import './vars.scss';
@import './scrollbar.scss';
@import './mixins.scss';

@font-face {
    font-family: 'CeraBrush-Thin';
    font-display: swap;
    src: url('../assets/fonts/CeraBrush_Thin.woff');
}

@font-face {
    font-family: 'CeraBrush-Bold';
    font-display: swap;
    src: url('../assets/fonts/CeraBrush_Bold.woff');
}

:root {
    font-size: 16px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font: inherit;
    vertical-align: baseline;
    font-size: inherit;
    user-select: inherit;
    -webkit-tap-highlight-color: transparent;
}

::selection {
    background-color: $highlight1;
    color: $fg2;
}

img,
video {
    width: 100%;
    height: auto;
    filter: blur(0px); //force gpu
}

//typography

p,
a,
span,
button,
input {
    color: $fg1;
    display: inline-block;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 2rem;
    // margin-bottom: 1rem;
    font-family: 'CeraBrush-Thin', 'Futura', sans-serif;
    word-break: break-word;
    text-underline-offset: 2px;
}

p {
    text-shadow: 2px 2px 0px $fg2;
}

a {
    margin: 0;
    display: inline;
}

h1,
h2,
h3,
h4 {
    font-family: 'CeraBrush-Bold', 'Futura', sans-serif;
    color: $fg1;
    text-shadow: 2px 2px 0px $fg2-fade;
    margin-bottom: 0.5rem;
}

h1 {
    display: block;
    font-weight: 300;
    font-size: 4rem;
    margin-bottom: 2rem;
    font-family: 'CeraBrush-Thin', 'Futura', sans-serif;
}

h2 {
    display: block;
    font-weight: 300;
    font-size: 1.25rem;
    white-space: nowrap;
}

h3 {
    display: block;
    font-size: 2.25rem;
    line-height: 2.5rem;
}

h4 {
    font-size: 1.25rem;
}

@media (max-width: $b-lg) {

    h1,
    h3 {
        font-size: 2.5rem;
        line-height: 2.25rem;
    }

    p,
    a {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }

    p {
        // margin-bottom: 2rem;
    }
}

@media (max-width: $b-sm) {

    h1 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }

    p,
    a,
    h4 {
        font-size: 1rem;
        line-height: 1.25rem;
    }

    .br-hide-sm {
        display: none;
    }
}

a {
    // pointer-events: auto;

    &:visited {
        color: $fg1;
    }

    &:hover,
    &:focus {
        color: $highlight1;
    }
}

strong {
    font-weight: 700;
}

html {
    // overflow-y: scroll;
    height: 100%;
}

body {
    background-color: $bg1;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

main {
    width: 100%;
}

.main-bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -2;
    overflow: hidden;
    opacity: 0;

    &--show {
        opacity: 0.4;
        transition: opacity $d-long linear $d-longest;
    }

    img {
        width: 100%;
        height: 100%;
    }
}

.main-webgl {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    outline: none;
    opacity: 0;

    &--show {
        opacity: 1;
        transition: opacity $d-long linear $d-longest;
    }
}

.dg,
.dg li,
.dg span {
    z-index: 9999 !important;
    color: white;
}

.main-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-sticky;
    width: 100%;
    padding: 1rem;
    pointer-events: none;

    @include fade-in;

    &__logo {
        @include logo-img;

        @media (max-width: $b-sm) {
            display: none;
        }
    }

    &__actions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;

        &__cta {
            @include cta-styles;
            pointer-events: auto;

            &--info {
                color: $fg1;
                background-color: $fg2-fade;

                &:hover,
                &:focus {
                    color: $fg1;
                    background-color: $highlight1;
                    border-color: $highlight1;
                }
            }

            &--tickets {
                color: $fg2;
                background-color: $bg2;
                letter-spacing: 2.5px;

                &:visited {
                    color: $fg2;
                }

                &:hover,
                &:focus {
                    color: $fg1;
                    background-color: $highlight1;
                    border-color: $highlight1;
                }
            }

            .hide {
                margin: 0;
                color: inherit;
                line-height: inherit;
                font-size: inherit;
                letter-spacing: inherit;

                @media (max-width: $b-sm) {
                    display: none;
                }
            }
        }
    }

    @media (max-width: $b-sm) {
        padding: 0.5rem;
        width: 100%;
        justify-content: center;
        min-height: 3.5rem;

        .main-nav__actions {
            gap: 0.5rem;
        }

        h2 {
            display: none;
        }
    }
}

.utility-nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-left: 1rem;
    padding-right: 1rem;
    position: fixed;
    bottom: 1rem;
    left: 0;
    width: 100%;
    height: 3.5rem;
    z-index: $z-sticky;
    user-select: none;
    pointer-events: none;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    transition: transform $d-standard $ease-in-out, -webkit-transform $d-standard $ease-in-out;

    &--hide {
        -webkit-transform: translateY(6rem);
        transform: translateY(6rem);
    }

    @media (max-height: 500px) {
        -webkit-transform: translateY(6rem);
        transform: translateY(6rem);
    }

    &__list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        position: relative;
        height: 100%;
        width: fit-content;
        list-style: none;
        user-select: auto;
        pointer-events: auto;

        &__item {
            margin-left: 4px;
            margin-right: 4px;
            width: 2rem;
            height: 100%;
            border-bottom: 4px solid $fg1-fade;
            cursor: pointer;
            transition: border-bottom-width $d-shorter $ease-in-out, border-bottom-color $d-shorter $ease-in-out;

            &__text {
                position: absolute;
                top: 0;
                left: 50%;
                width: 100%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                text-align: center;
                text-decoration: none;
                opacity: 0;
                transition: opacity $d-shorter $ease-in-out;
                color: $fg1;
                user-select: none;
                pointer-events: none;

                &:hover {
                    color: $fg1;
                    opacity: 1;
                }
            }

            &:hover,
            &--current {
                border-bottom-width: 8px;
                border-bottom-color: $fg1;

                .utility-nav__list__item__text {
                    opacity: 1;
                    user-select: auto;
                    pointer-events: auto;
                }
            }
        }
    }
}

.perspective-cont {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    perspective: 150px; //setting this too low causes webkit to try to repaint huge images
    perspective-origin: 50% 50%;
    // -webkit-transform-style: preserve-3d;
    // transform-style: preserve-3d;
    -webkit-transform-style: flat;
    transform-style: flat;
    -moz-transform-style: preserve-3d; //firefox perspective https://stackoverflow.com/questions/23080112/css-perspective-not-working-in-firefox
}

.perspective {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    will-change: transform;
    backface-visibility: hidden;
    opacity: 0;
    pointer-events: none;
    user-select: none;

    &--current {
        opacity: 1;
        pointer-events: auto;
        user-select: auto;
        z-index: 1;

        .main-content__text--highlight {

            &::after {
                background-position: 0 0;
            }

        }

        .main-content__img {
            -webkit-mask-position: 0 0;
            mask-position: 0 0;
            opacity: 1;
        }
    }
}

.perspective-scroll {
    overflow: hidden;
    will-change: transform;

    //-padding
    &::before,
    &::after {
        content: '';
        position: static;
        display: block;
        width: 100%;
        height: 7rem;
        min-height: 7rem;
    }

    &::before {
        @media (max-width: $b-sm) {
            height: 3.5rem;
            min-height: 3.5rem;
        }
    }
}

.main-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 4rem;
    height: 4rem;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    user-select: none;
    pointer-events: none;
    opacity: 1;

    &--hide {
        opacity: 0;
        transition: opacity $d-long linear;
    }

    &__img {
        width: 100%;
        height: 100%;
        animation: loading-rotate $d-longer linear infinite both;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);

        @keyframes loading-rotate {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
    }
}

.main-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 2rem;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    opacity: 0;
    overflow: visible;

    &::before,
    &::after {
        content: none;
    }

    @media (max-width: $b-sm) {
        padding: 4rem 4rem 2rem;
    }

    @media (max-width: $b-xs) {
        padding: 4rem 2rem 2rem;
    }

    @media (max-height: 350px) {
        padding: 4rem 1.5rem 2rem;
        justify-content: flex-start;
    }

    &--show {
        opacity: 1;
        transition: opacity $d-longer $ease-in-out-2 $d-longest;
    }

    &__img-logo {
        @include logo-img;
        display: none;
        height: 2.5rem;
        margin-bottom: 0.25rem;
        margin-left: auto;
        margin-right: auto;
        pointer-events: none;

        @media (max-width: $b-sm) {
            display: block;
        }
    }

    &__logo {

        line-height: 1rem;
        margin-bottom: 2rem;
        font-weight: 400;
        text-align: center;

        &--style-0 {
            font-weight: 400;
            font-size: 2rem;
            line-height: 2.5rem;
        }

        &--style-1 {
            font-weight: 400;
            font-size: 2.4rem;
            line-height: 2rem;
            font-family: 'CeraBrush-Bold', 'Futura', sans-serif;
            margin: 0;
        }

        &--style-2 {
            font-weight: 400;
            font-size: 4.5rem;
            line-height: 3.25rem;
            font-family: 'CeraBrush-Bold', 'Futura', sans-serif;
            margin: 0;
        }

        &--style-3 {
            font-weight: 400;
            font-size: 1.25rem;
            line-height: 1rem;
        }

        @media (max-width: $b-sm) {
            margin-bottom: 0.5rem;

            &--style-0 {
                font-size: 1rem;
                line-height: 0.75rem;
            }

            &--style-1 {
                font-size: 1rem;
                line-height: 0.75rem;
            }

            &--style-2 {
                font-size: 2rem;
                line-height: 1.75rem;
            }

            &--style-3 {
                font-size: 1rem;
                line-height: 1rem;
            }
        }
    }

    &__content {
        margin-bottom: 2.5rem;
        line-height: 1.5rem;
        text-align: center;

        @media (max-width: $b-sm) {
            margin-bottom: 1rem;
            line-height: 1rem;
        }
    }

    &__cta {
        @include cta-styles;
        background-color: transparent;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid $fg1;
            border-radius: 100vh;
            opacity: 0.5;
            animation: banner-ping $d-longest $ease-in-out infinite both;
            pointer-events: none;

            @keyframes banner-ping {
                0% {
                    -webkit-transform: scale(1.0);
                    transform: scale(1.0);
                    opacity: 0.5;
                }

                30% {
                    -webkit-transform: scale(1.8);
                    transform: scale(1.8);
                    opacity: 0;
                }

                100% {
                    -webkit-transform: scale(1.8);
                    transform: scale(1.8);
                    opacity: 0;
                }
            }
        }

        &:hover,
        &:focus {
            color: $fg1;
            background-color: $highlight1;
            border-color: $highlight1;
        }
    }
}

.main-content {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 10;
    max-width: $b-lg;
    margin: 0 auto;
    padding: 0vh 2rem 0;
    overflow: visible;

    &--flex-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &--flex-end {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &--left {
        padding-right: calc(50% - 8rem);
    }

    &--right {
        padding-left: calc(50% - 8rem);
    }

    &--center {
        padding-left: calc(25% - 4rem);
        padding-right: calc(25% - 4rem);
    }

    @media (max-width: $b-md) {
        padding: 0vh 1rem 0;
        // margin-top: 4.5rem;

        &--left,
        &--right,
        &--center {
            padding: 0;
        }

        &--m-flex-start {
            justify-content: flex-start;
        }

        &--m-flex-end {
            justify-content: flex-end;
        }

        &--m-flex-center {
            justify-content: center;

            @media (max-width: $b-xs) {
                justify-content: flex-start;
            }

            @media (max-height: 400px) {
                justify-content: flex-start;
            }
        }

        &--m-half-top {
            height: calc(40vh - 0.5rem);
            margin-top: 7rem;
            margin-bottom: calc(60vh - 6.5rem);

            &--large {
                height: calc(60vh - 0.5rem);
                margin-top: 7rem;
                margin-bottom: calc(40vh - 6.5rem);
            }

            &.perspective-scroll::after {
                content: none;
            }
        }

        &--m-half-bot {
            height: calc(60vh - 7.5rem);
            margin-top: calc(40vh + 7.5rem);

            &--small {
                height: calc(40vh - 7.5rem);
                margin-top: calc(60vh + 7.5rem);
            }

            &.perspective-scroll::before {
                content: none;
            }
        }

        &--m-full {
            padding: 0;
        }
    }

    @media (max-width: $b-sm) {
        &--m-half-top {
            height: calc(40vh - 0.25rem);
            margin-top: 3.5rem;
            margin-bottom: calc(60vh - 3.25rem);

            &--large {
                height: calc(60vh - 0.25rem);
                margin-top: 3.5rem;
                margin-bottom: calc(40vh - 3.25rem);
            }
        }

        &--m-half-bot {
            height: calc(60vh - 3.75rem);
            margin-top: calc(40vh + 3.75rem);

            &--small {
                height: calc(40vh - 3.75rem);
                margin-top: calc(60vh + 3.75rem);
            }
        }
    }

    &__text {
        position: relative;
        display: block;
        width: 100%;
        z-index: 10;
        // margin-bottom: 1rem;

        &--right {
            text-align: right;
        }

        &--center {
            text-align: center;

            @media (max-width: $b-sm) {
                text-align: left;
            }
        }

        &--offset {
            margin-top: 10%;

            @media (max-width: $b-md) {
                margin-top: 0;
            }
        }

        &--offset-neg {
            margin-bottom: 10%;

            @media (max-width: $b-md) {
                margin-bottom: 0;
            }
        }

        &--left-layout {
            margin-right: 65%;
            width: 35%;
        }

        &--right-layout {
            margin-left: 65%;
            width: 35%;
        }

        &--center-layout {
            margin-left: auto;
            margin-right: auto;
            width: 60%;
        }

        @media (max-width: $b-md) {


            &--right,
            &--center,
            &--left-layout,
            &--right-layout {

                margin-left: 0;
                margin-right: 0;
                width: 100%;

            }

            &--center-layout {
                width: 100%;
            }
        }

        &--light {
            color: $fg2;
        }

        &--quote {
            font-style: italic;

            &::before {
                content: '"';
                position: absolute;
                left: -3rem;
                font-family: 'CeraBrush-Bold', 'Futura', sans-serif;
                font-size: 4rem;
            }

            @media (max-width: $b-md) {
                &::before {
                    font-size: 2rem;
                    top: -0.75rem;
                    left: -1rem;
                }
            }
        }

        &--signature {
            margin-top: 2rem;
            font-size: 1rem;
            font-style: italic;

            @media (max-width: $b-md) {
                margin-top: 1.5rem;
            }
        }

        &--terms {
            font-size: 1rem;
            font-style: italic;
        }

        &--dropdown-cont-head {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 0;
            cursor: pointer;
            user-select: none;

            &:hover {
                text-decoration: underline;
                text-decoration-thickness: 3px;
                text-underline-offset: 3px;
            }

            &__handle {
                width: 2rem;
                height: 2rem;
                min-width: 2rem;
                min-height: 2rem;
                z-index: 1;
                margin-right: 0.75rem;

                img {
                    transform: translateY(20%) rotate(0deg);
                    transition: transform $d-short $ease-in-out;
                }

                @media (max-width: $b-sm) {
                    width: 1rem;
                    height: 1rem;
                    min-width: 1rem;
                    min-height: 1rem;
                    margin-right: 0.25rem;

                    img {
                        transform: translateY(40%) rotate(0deg);
                        transition: transform $d-short $ease-in-out;
                    }
                }
            }

            &--show {
                text-decoration: underline;
                text-decoration-thickness: 3px;
                text-underline-offset: 3px;

                .main-content__text--dropdown-cont-head__handle {

                    img {
                        transform: translateY(20%) rotate(90deg);
                    }

                    @media (max-width: $b-sm) {
                        img {
                            transform: translateY(40%) rotate(90deg);
                        }
                    }

                }
            }
        }

        &--dropdown-cont {
            display: none;
            padding-left: 2.5rem;

            &--show {
                display: block;
            }

            @media (max-width: $b-sm) {
                padding-left: 0;
            }
        }

        &--dropdown {
            position: relative;
            margin-top: 2rem;
            pointer-events: auto;
            cursor: pointer;

            @media (max-width: $b-sm) {
                margin-top: 1rem;
            }

            &__head {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                user-select: none;
                pointer-events: inherit;

                @media (max-width: $b-sm) {
                    align-items: flex-start;
                }

                &:focus {
                    background-color: transparent;
                }

                &:hover {
                    text-decoration: underline;
                    text-decoration-thickness: 2px;
                    text-underline-offset: 2px;
                }

                >* {
                    display: block;
                    margin: 0;
                }

                &__handle {
                    width: 1rem;
                    height: 1rem;
                    min-width: 1rem;
                    min-height: 1rem;
                    z-index: 1;
                    margin-right: 0.5rem;

                    img {
                        transform: translateY(0%) rotate(0deg);
                        transition: transform $d-short $ease-in-out;
                    }

                    @media (max-width: $b-sm) {
                        margin-right: 0.25rem;

                        img {
                            transform: translateY(20%) rotate(0deg);
                            transition: transform $d-short $ease-in-out;
                        }
                    }
                }
            }

            &__body {
                display: none;
                padding-left: 1.5rem;
                cursor: auto;

                @media (max-width: $b-sm) {
                    padding-left: 1.25rem;
                }
            }

            &--show {
                .main-content__text--dropdown__head {
                    margin-bottom: 1rem;
                    text-decoration: underline;
                    text-decoration-thickness: 2px;
                    text-underline-offset: 2px;

                    @media (max-width: $b-sm) {
                        margin-bottom: 0.5rem;
                    }
                }

                .main-content__text--dropdown__head__handle {
                    img {
                        transform: translateY(0%) rotate(90deg);
                    }

                    @media (max-width: $b-sm) {
                        img {
                            transform: translateY(20%) rotate(90deg);
                        }
                    }
                }

                .main-content__text--dropdown__body {
                    display: block;
                }
            }
        }
    }

    &__media {

        // transform: translate3d(0, 0, -10px); //impossible due to flattening by parent opacity

        user-select: none;
        // pointer-events: none;

        &--left {
            position: absolute;
            left: 2rem;
            width: calc(65% - 5rem);
        }

        &--right {
            position: absolute;
            right: 2rem;
            width: calc(65% - 5rem);
        }

        &--center {
            width: calc(65% - 5rem);
            margin-left: auto;
            margin-right: auto;
        }

        &--small {
            position: absolute;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }

        @media (max-width: $b-md) {

            margin: 0;
            margin-bottom: 2rem;

            &--left,
            &--right,
            &--center {
                position: static;
                width: 100%;
                height: auto;
            }

            &--small {
                // height: 100%;
                // width: auto;
                margin: 0;
            }
        }
    }

    &__divider {
        margin-bottom: 3rem;
        width: 100%;

        @media (max-width: $b-sm) {
            margin-bottom: 2rem;
        }
    }

    &__actions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 60%;
        margin: 2rem auto 0 auto;

        @media (max-width: $b-md) {
            width: 100%;
        }

        &__cta,
        &__cta:visited {
            @include cta-styles;
            width: 100%;
            min-height: 4rem;
            font-size: 1.5rem;
            border-radius: 2rem;
            background-color: $fg1;
            color: $fg2;
            letter-spacing: 2.5px;
            margin: 0;

            &:hover,
            &:focus {
                background-color: $highlight1;
                border-color: $highlight1;
                color: $fg1;
            }
        }
    }
}

.main-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    transition: transform $d-standard $ease-in-out, -webkit-transform $d-standard $ease-in-out;
    pointer-events: none;

    &--hide {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }

    &--show {
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: $gutter;

        &__logo {
            @include logo-type;
            pointer-events: auto;

            h2 {
                margin: 0;
            }
        }

        &__tag {
            display: inline-block;
            text-align: right;
            text-decoration: none;
            margin: 0;
            pointer-events: auto;
        }

        &__sm {
            display: flex;
            height: 1.5rem;
            margin-top: 0.25rem;

            a {
                width: 1.5rem;
                margin-right: 0.5rem;
                pointer-events: auto;
            }
        }
    }

    @media (max-width: $b-sm) {
        padding: 0.5rem;

        .main-footer__row__tag {
            font-size: 0.75rem;
        }

        .main-footer__row__sm {
            a {
                margin-right: 0.25rem;
            }
        }
    }
}